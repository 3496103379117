export const textContent = {
  billing: {
    details: {
      nameOnCard: 'Name on Card',
      card: 'Card',
      expires: 'Expires',
      address: {
        billingAddress: 'Billing Address',
        unit: 'Unit (optional)',
        city: 'City',
      },
      cardNumber: 'Card Number',
    },
    title: 'Billing',
    description: 'Your Avenue 8 membership fee will be automatically billed to this payment method on the 1st day of every month:',
    changesSaved: '✓ Your changes have been saved',
    endingIn: (last4: string | number) => `card ending in ${last4}`,
    cardEndingIn: (last4: string | number, cardType: string) =>
      `${cardType} card ending in ${last4}`,
    save: 'Save',
    cancel: 'Cancel',
    remove: 'Remove',
    edit: 'Edit',
    add: 'Add',
    membershipCode: 'Membership Code (optional)',
    paymentMethod: 'Payment Method',
    paymentAuthorization: 'Checking this box authorizes Avenue 8 to bill your membership fee to this payment method on the 1st of every month and prorate the amount owed upon joining.',
    paymentUpdated: 'Your payment method has been updated.',
    paymentAdded: 'Thanks! Your payment method has been added.',
    paymentRemoved: 'Your payment method has been removed.',
    paymentNotVerified: 'Payment method not verified. Please try again.',
    confirmRemove: 'Confirm Remove',
    confirmRemovePayment: 'If you would like to remove this payment method, click Confirm Remove. Disabling this payment method will not cancel any of your open requests that use this method.',
    authorization: 'Authorization',
    paymentAuthorizationAgreement: 'You authorize Avenue 8 to regularly charge your credit card on the 1s​t​ of every month for 1/12t​h​ of your annual Platform Fee. A receipt will be emailed to you and will appear on your credit card statement as “Avenue 8”. You authorize Avenue 8 to keep this card on file for use for one-time fees incurred from supplemental services, such as advertising or promotional items.',
    errors: {
      pleaseEnterName: 'Please enter a name',
      pleaseEnterCardInfo: 'Please enter card information',
      addPaymentMethod: 'Add a payment method to continue',
      unauthorized: 'Hmm, seems like you don\'t have authorization to access this!',
      contactUs: 'If you think this is a mistake: try signing in again or contact us!'
    },
  },
  avenue8: 'AVENUE 8',
};
