import React from 'react';
import { Typography } from 'av8-ui';

import { ErrorMessageContainer } from './containers';

export const ErrorMessage = ({
  message = '',
  variant,
}: {
  message: string;
  variant: string;
}) => (
  <ErrorMessageContainer variant={variant}>
    <Typography style={{ color: 'red' }} variant={'Body'}>
      {message}
    </Typography>
  </ErrorMessageContainer>
);
