import React from 'react';

import { useQueryParams } from '../hooks';
import { StyledTitle } from '../components/billing/common/textComponents';
import { CheckoutForm } from '../components/billing';
import { textContent } from '../utils';

export const EditPaymentMethodPage = () => {
  const noDefaultPaymentMethod =
    useQueryParams().get('noDefaultPaymentMethod') === 'true';
  const title = noDefaultPaymentMethod
    ? textContent.billing.add
    : textContent.billing.edit;

  return (
    <>
      <StyledTitle
        boldText={title}
        italicText={textContent.billing.paymentMethod}
      />
      <CheckoutForm showMembershipCodeInput={noDefaultPaymentMethod} />
    </>
  );
};
