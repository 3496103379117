import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { Input, InputContainer } from 'av8-ui';

import { textContent } from '../../../utils';
import { FormInputContainer, CardElementContainer } from './containers';
import { ErrorMessage } from './components';

export const FormInput = ({
  label,
  onChange,
  error = '',
}: {
  label: string;
  error?: string;
  onChange: (e: any) => void;
}) => {
  return (
    <FormInputContainer variant={error ? 'error' : undefined}>
      <InputContainer label={label}>
        <Input onChange={onChange} style={{ width: '100%' }} />
      </InputContainer>
      <ErrorMessage variant="secondary" message={error}></ErrorMessage>
    </FormInputContainer>
  );
};

export const CardInput = ({ error }: { error: string }) => {
  return (
    <FormInputContainer>
      <InputContainer label={textContent.billing.details.cardNumber}>
        <CardElementContainer variant={error ? 'error' : undefined}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '15px',
                },
              },
            }}
          />
        </CardElementContainer>
      </InputContainer>
      <ErrorMessage variant="primary" message={error}></ErrorMessage>
    </FormInputContainer>
  );
};
