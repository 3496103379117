import { gql } from '@apollo/client';

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription($priceId: String, $promotionCode: String) {
    createSubscription(priceId: $priceId, promotionCode: $promotionCode) {
      subscriptionId
      clientSecret
    }
  }
`;

export const HELLO = gql`
  query {
    hello
  }
`;

export const ADD_PAYMENT_METHOD = gql`
  mutation AddPaymentMethod {
    addPaymentMethod {
      clientSecret
    }
  }
`;

export const IS_SUBSCRIBED = gql`
  query {
    isSubscribed
  }
`;

export const SET_PRIMARY_PAYMENT_METHOD = gql`
  mutation SetPrimaryPaymentMethod($defaultPaymentId: String) {
    setPrimaryPaymentMethod(defaultPaymentId: $defaultPaymentId)
  }
`;

export const CONFIG = gql`
  query {
    config {
      stripePublishableKey
    }
  }
`;

export const CHECKOUT_FORM_CONFIG = gql`
  query {
    isSubscribed
    config {
      agentSubscriptionId
    }
    subscriptions {
      id
      collection_method
      default_payment_method
      days_until_due
    }
  }
`;

export const DEFAULT_PAYMENT_METHOD = gql`
  query {
    defaultPaymentMethod {
      id
      object
      card {
        exp_month
        exp_year
        last4
        brand
        funding
      }
      billing_details {
        name
      }
    }
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation RemovePaymentMethod($paymentId: String) {
    removePaymentMethod(paymentId: $paymentId)
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query {
    subscriptions {
      id
      collection_method
      default_payment_method
      discount {
        promotion_code
      }
      days_until_due
    }
  }
`;

export const APPLY_PROMOTION_TO_SUBSCRIPTION = gql`
  mutation ApplyPromotionToSubscription(
    $subscriptionId: String
    $promotionCode: String
  ) {
    applyPromotionToSubscription(
      subscriptionId: $subscriptionId
      promotionCode: $promotionCode
    )
  }
`;
