import React from 'react';
import { Typography } from "av8-ui";
import { textContent } from '../utils/textContent';

export const Unauthorized = () => {
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
    padding: 30
  }}>
    <Typography variant='Subheading' fontWeight='bold' textAlign='center'>{textContent.billing.errors.unauthorized}</Typography>
    <Typography variant='Body' fontWeight='normal' textAlign='center'>{textContent.billing.errors.contactUs}</Typography>
  </div>;
}
