import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';

import { ServerClient } from './services/billing-api';
import AppInitialization from './components/custom/AppInitialization';
import { HeapIntegration } from './components/custom/HeapIntegration';
import { CombinedContexts, StripeContext } from './components/context';
import { Routing } from './components/router';
import { defaultTheme } from './assets/theme';
import './assets/fonts/styrenea.scss';
import './assets/fonts/bookmania.scss';
import './App.scss';

const App = () => {
  return (
    <ApolloProvider client={ServerClient}>
      <CombinedContexts>
        <AppInitialization>
          <HeapIntegration />
          <StripeContext>
            <ThemeProvider theme={defaultTheme}>
              <StylesProvider injectFirst={true}>
                <Routing />
              </StylesProvider>
            </ThemeProvider>
          </StripeContext>
        </AppInitialization>
      </CombinedContexts>
    </ApolloProvider>
  );
};

export default App;
