import React from 'react';
import styled from 'styled-components';
import { Typography } from 'av8-ui';

import { StyledTitle } from '../billing/common/textComponents';
import ModalLogo from '../../assets/images/AV8Loading.gif';
import { CardPreview } from '../billing/common/components';
import {
  SmallCardContainer,
  ImageContainer,
  LargeCardContainer,
  StyledButtonRow,
} from './containers';
import {
  RemovePaymentButton,
  SmallerCancelButton,
} from '../billing/checkout/buttons';
import { textContent } from '../../utils';

export const StyledRemovePaymentButton = styled(RemovePaymentButton)`
  margin: 30px 10% 0 0;
`;

export const PaymentMessage = ({ message }: { message?: string }) => {
  return (
    <SmallCardContainer>
      <ImageContainer>
        <img height={58} width={150} src={ModalLogo} alt="Avenue 8 logo"></img>
      </ImageContainer>
      <Typography variant="Subheading">{message}</Typography>
    </SmallCardContainer>
  );
};

export const RemovePaymentCard = ({
  handleCancel,
  handleRemove,
}: {
  handleCancel: () => void;
  handleRemove: () => void;
}) => {
  return (
    <LargeCardContainer>
      <StyledTitle
        boldText={textContent.billing.remove}
        italicText={textContent.billing.paymentMethod + '?'}
      />
      <CardPreview />
      <Typography variant="Caption" fontWeight="bold">
        {textContent.billing.confirmRemovePayment}
      </Typography>
      <StyledButtonRow>
        <SmallerCancelButton onClick={handleCancel} />
        <StyledRemovePaymentButton onClick={handleRemove} />
      </StyledButtonRow>
    </LargeCardContainer>
  );
};
