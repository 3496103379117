import React from 'react';
import { useQuery } from '@apollo/client';
import { Loading } from 'av8-ui';
import { useHistory } from 'react-router-dom';

import { ROUTE_PATHS } from '../../router/routes';
import { DEFAULT_PAYMENT_METHOD } from '../../../services/billing-api/queries';
import { useQueryParams } from '../../../hooks';
import { BillingCard } from './components';
import { LoadingContainer } from '../checkout/containers';

export const BillingDetails = () => {
  const history = useHistory();

  const status = useQueryParams().get('status');

  const waitForModal = status === 'removeSuccess' || status === 'notVerified';

  const noDefaultPaymentMethod =
    useQueryParams().get('noDefaultPaymentMethod') === 'true';

  const messageModal = status === 'updateSuccess' || status === 'addSuccess';

  const { loading, error, data } = useQuery(DEFAULT_PAYMENT_METHOD, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  // TODO handle error
  if (error) console.error(error);

  if (
    !loading &&
    !data?.defaultPaymentMethod?.card &&
    !noDefaultPaymentMethod &&
    !messageModal
  ) {
    if (!waitForModal)
      history.push(ROUTE_PATHS.editPaymentDetails.noDefaultPaymentMethod);
    else {
      setTimeout(function () {
        history.push(ROUTE_PATHS.editPaymentDetails.noDefaultPaymentMethod);
      }, 3000);
    }
  }

  const card = data?.defaultPaymentMethod?.card;
  const name = data?.defaultPaymentMethod?.billing_details?.name;

  const BillingCardComponent = () =>
    !loading && data?.defaultPaymentMethod ? (
      <BillingCard {...{ ...card, name }} />
    ) : (
      <></>
    );

  // TODO use loading component
  const Component = () =>
    loading ? (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    ) : (
      <BillingCardComponent />
    );

  return <Component />;
};
