import React, { createContext, useContext, useState } from 'react';

export type AuthenticationContext = {
  isAuthed: boolean,
  setIsAuthed: (isAuthed: boolean) => void,
  authSBL: any,
  setAuthSBL: (sbl: any) => void,
  agentId: any,
  setAgentId: (agentId: any) => void,
}

const AuthContext = createContext<AuthenticationContext>({
  isAuthed: false,
  setIsAuthed: () => null,
  authSBL: null,
  setAuthSBL: () => null,
  agentId: null,
  setAgentId: () => null,
});

export const useAuthContext = () => useContext<AuthenticationContext>(AuthContext);

export const AuthContextProvider = ({ children }: { children: any }) => {
  const [agentId, setAgentId] = useState(null);
  const [isAuthed, setIsAuthed] = useState(false);
  const [authSBL, setAuthSBL] = useState(null);

  return <AuthContext.Provider value={{
    isAuthed,
    setIsAuthed,
    authSBL,
    setAuthSBL,
    agentId,
    setAgentId,
  }}
  >
    {children}
  </AuthContext.Provider>
}
