export type RouteDictionary = {
  [area: string]:
    | {
        [route: string]: string;
      }
    | string;
};

function cleanPathString(path: string) {
  return path.replace(/\/\//g, '/').replace(/\/$/, '');
}

export function prefixRoutes<T extends RouteDictionary>(
  prefix: string,
  input: T,
): T {
  return Object.fromEntries(
    Object.entries(input).map(([area, paths]) => {
      if (typeof paths === typeof '') {
        return [area, cleanPathString(`${prefix}${paths}`)];
      }

      const prefixedPaths = Object.fromEntries(
        Object.entries(paths).map(([name, path]) => {
          const prefixedPath = `${prefix}${path}`;

          const cleanPath = cleanPathString(prefixedPath);

          return [name, cleanPath];
        }),
      );

      return [area, prefixedPaths];
    }),
  );
}
