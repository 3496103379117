import React, { useState, useEffect } from 'react';
import { Loading } from 'av8-ui';

import { textContent } from '../../utils';
import { EditPaymentMethodPage } from '../../pages';
import { ModalBackground, LargestCardContainer } from './containers';
import { PaymentMessage, RemovePaymentCard } from './components';

export const PaymentMethodModal = ({
  isActive,
  variant,
}: {
  isActive: boolean;
  variant: string;
}) => {
  const [show, setShow] = useState(true);

  const message = {
    removeSuccess: textContent.billing.paymentRemoved,
    updateSuccess: textContent.billing.paymentUpdated,
    addSuccess: textContent.billing.paymentAdded,
    notVerified: textContent.billing.paymentNotVerified,
  }[variant];

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShow(false);
    }, 3000);

    return () => {
      clearTimeout(timeId);
      setShow(true);
    };
  }, [variant, isActive]);

  if (!isActive || !show || !message) {
    return null;
  }

  return (
    <ModalBackground>
      <PaymentMessage message={message} />
    </ModalBackground>
  );
};

export const RemovePaymentModal = ({
  isActive,
  handleCancel,
  handleRemove,
  loading,
}: {
  isActive: boolean;
  handleCancel: () => void;
  handleRemove: () => void;
  loading: boolean;
}) => {
  if (!isActive) {
    return null;
  }

  const Content = () =>
    loading ? (
      <Loading />
    ) : (
      <RemovePaymentCard
        handleCancel={handleCancel}
        handleRemove={handleRemove}
      />
    );
  return (
    <ModalBackground>
      <Content />
    </ModalBackground>
  );
};

export const EditPaymentModal = ({ isActive }: { isActive: boolean }) => {
  if (!isActive) {
    return null;
  }
  return (
    <ModalBackground>
      <LargestCardContainer>
        <EditPaymentMethodPage />
      </LargestCardContainer>
    </ModalBackground>
  );
};
