import styled from 'styled-components';
import { ButtonRow } from '../billing/common/containers';
import { breakpoints } from '../../utils';

export const ModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  min-height: 100vh;
`;

export const CardContainer = styled.div`
  margin: auto;
  background-color: #fcfcfc;
  max-width: 250px;
  text-align: center;
  padding: 40px 20px 10px 20px;
`;

export const SmallCardContainer = styled(CardContainer)`
  height: 150px;
  margin-top: 270px;
  @media (max-width: ${breakpoints.MOBILE}) {
    margin-top: 100px;
  }
`;

export const LargeCardContainer = styled(CardContainer)`
  height: 350px;
  padding: 10px 25px 30px 25px;
  text-align: left;
  border-radius: 10px;
  min-width: 290px;
  margin-top: 30px;
  @media (max-width: ${breakpoints.MOBILE}) {
    margin-top: 100px;
  }
`;

export const LargestCardContainer = styled(CardContainer)`
  min-height: 545px;
  padding: 10px 20px 0px 20px;
  text-align: left;
  border-radius: 10px;
  max-width: 300px;
  margin-top: 10%;
  @media (max-width: ${breakpoints.MOBILE}) {
    margin-top: 100px;
  }
`;

export const ImageContainer = styled.div`
  display: block;
  margin-bottom: 30px;
`;

export const StyledButtonRow = styled(ButtonRow)`
  margin-top: 30px;
`;
