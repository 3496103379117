import React, { createContext, useState, useContext } from 'react';

export type RequestContextType = {
  isEmbedded: boolean,
  setIsEmbeddedCtx: (isEmbedded: boolean) => void
}

export const RequestContext = createContext<RequestContextType>({ 
  isEmbedded: false, 
  setIsEmbeddedCtx: () => null,
});

export const useRequestContext = () => useContext<RequestContextType>(RequestContext);

export default function RequestContextProvider({ children }: { children: any }) {
  const [isEmbedded, setIsEmbeddedCtx] = useState(false);

  return (
    <RequestContext.Provider
      value={{
        isEmbedded,
        setIsEmbeddedCtx,
      }}
    >
      {children}
    </RequestContext.Provider>
  );
}
