import React, { useState } from 'react';
import { Loading } from 'av8-ui';

import { SaveButton, CancelButton } from './buttons';
import { FormInput, CardInput } from './inputs';
import { useFormState } from './useFormState';
import { LoadingContainer } from './containers';
import { Confirmation, HorizontalLine } from '../common/components';
import { ButtonRow } from '../common/containers';
import { ROUTE_PATHS } from '../../router/routes';
import { textContent } from '../../../utils';
import { validFormSchema } from './helpers';
import { ErrorHeading } from '../common/textComponents';

export const CheckoutForm = ({ showMembershipCodeInput = true }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [isSubmitActive, setIsSubmitActive] = useState(true);

  const {
    history,
    isInputValid,
    noDefaultPaymentMethod,
    handleNameChange,
    handleMembershipCodeChange,
    handleAgreementChange,
    handleSubmit,
    isCardValid,
    cardError,
    nameError,
    showErrors,
    name,
    membershipCode,
    didAgree,
  } = useFormState();

  const submitHandler = async (event: any) => {
    const isValid = validFormSchema.isValidSync({
      isCardValid,
      name,
      membershipCode: membershipCode.trim(),
      didAgree,
    });

    if (isValid) {
      setIsSubmitActive(false);
      handleSubmit(event);
    }
  };

  const shouldShowLoading = !isSubmitActive && isInputValid;

  if (shouldShowLoading !== showLoading) setShowLoading(shouldShowLoading);

  return (
    <>
      <LoadingContainer style={{ display: !showLoading ? 'none' : undefined }}>
        <Loading />
      </LoadingContainer>
      <ErrorHeading active={noDefaultPaymentMethod && !isInputValid} />
      <div style={{ display: showLoading ? 'none' : undefined }}>
        <FormInput
          onChange={handleNameChange}
          label={textContent.billing.details.nameOnCard}
          error={showErrors && nameError ? nameError : ''}
        />
        <CardInput error={showErrors && !isCardValid ? cardError : ''} />
        {showMembershipCodeInput ?
          <FormInput
            onChange={handleMembershipCodeChange}
            label={textContent.billing.membershipCode}
          />
          : null
        }
        <Confirmation onClick={handleAgreementChange} />
        <HorizontalLine variant="alt" />
        <ButtonRow variant="alt">
          <CancelButton
            isActive={!noDefaultPaymentMethod}
            onClick={() => history.push(ROUTE_PATHS.billingDetails.base)}
          />
          <SaveButton
            isActive={isInputValid && isSubmitActive}
            onClick={submitHandler}
          />
        </ButtonRow>
      </div>
    </>
  );
};
