import React from 'react';
import { Typography } from 'av8-ui';

import { textContent } from '../../../utils';
import {
  DescriptionContainer,
  TitleContainer,
  SubheadingContainer,
} from './containers';

export const Description = () => {
  return (
    <DescriptionContainer>
      <Typography variant="Body" style={{ color: 'grey' }}>
        {textContent.billing.description}
      </Typography>
    </DescriptionContainer>
  );
};

export const Title = () => {
  return (
    <TitleContainer>
      <Typography variant="Title" fontWeight={'bolder'}>
        {textContent.billing.title}
      </Typography>
    </TitleContainer>
  );
};

export const StyledTitle = ({
  boldText,
  italicText,
}: {
  boldText: string;
  italicText: string;
}) => {
  return (
    <TitleContainer>
      <Typography
        style={{ display: 'inline-block' }}
        variant="Title"
        fontWeight={'bolder'}
      >{`${boldText}`}</Typography>{' '}
      <Typography variant="Title" fontWeight={'lighter'} fontStyle={'italic'}>
        {italicText}
      </Typography>
    </TitleContainer>
  );
};

export const Subheading = ({ active }: { active: boolean }) => {
  return (
    <SubheadingContainer>
      <Typography
        variant="Body"
        style={{ color: '#6fd3ab', visibility: active ? undefined : 'hidden' }}
      >
        {textContent.billing.changesSaved}
      </Typography>
    </SubheadingContainer>
  );
};

export const ErrorHeading = ({ active }: { active: boolean }) => {
  return (
    <SubheadingContainer>
      <Typography
        variant="Body"
        style={{ color: 'red', visibility: active ? undefined : 'hidden' }}
      >
        {textContent.billing.errors.addPaymentMethod}
      </Typography>
    </SubheadingContainer>
  );
};
