import styled from 'styled-components';
import { breakpoints } from '../../../utils';

interface Props {
  variant?: string;
}

export const DescriptionContainer = styled.div`
  margin: 35px 0px 45px 0px;
  text-align: center;
  @media (max-width: ${breakpoints.TABLET}) {
    text-align: left;
  }
`;

export const TitleContainer = styled.div`
  text-align: center;
  @media (max-width: ${breakpoints.TABLET}) {
    text-align: left;
  }
  margin: 20px 0px 10px 0px;
`;

export const SubheadingContainer = styled.div<Props>`
  margin: 20px 0px 10px 0px;
`;

export const ButtonContainer = styled.div`
  width: 30%;
  margin: 30px 10% 0 10%;
  display: inline-block;
`;

export const ConfirmationContainer = styled.div`
  display: block;
  button {
    margin-top: -10px;
  }
`;

export const ConfirmationHeaderContainer = styled.div`
  margin-top: 25px;
  margin-bottom: 10px;
  display: inline-block;
  img {
    margin-left: 10px;
    display: inline-block;
  }
`;

export const ToolTipImgContainer = styled.div`
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 3px;
`;

export const ToolTipMessageContainer = styled.div`
  div {
    padding: 10px 10px 10px 10px;
    position: absolute;
    min-width: 200px;
    max-width: 200px;
    bottom: 140%;
    left: -15px;
    background-color: white;
    z-index: 20;
    filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.12));
    box-sizing: border-box;
    background: white;
  }
  div:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    margin-left: -0.5em;
    left: 20px;
    top: 100%;
    box-sizing: border-box;

    border: 8px solid white;
    border-color: transparent transparent white white;

    transform-origin: 0 0;
    transform: rotate(-45deg);

    filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.12));
  }
`;

export const CardPreviewContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 20px;
`;

export const ThinCardPreviewContainer = styled.div`
  display: flex;
`;

export const ButtonRow = styled.div<Props>`
  margin-top: ${props => (props.variant === 'alt' ? '30px' : '60px')};
  display: grid;
  grid-template-areas: 'left right';
  width: 100%;
  column-gap: 20px;
  padding-bottom: 25px;
`;

export const CheckBoxContainer = styled.div`
  display: inline-block;
  grid-area: checkbox;
  align-self: end;
  padding-top: 11px;
`;

export const ConfirmationCheckboxContainer = styled.div`
  display: grid;
  grid-template-areas: 'checkbox text';
  grid-template-columns: 30px 1fr;
  grid-column-gap: 20px;
`;

export const AuthorizationTextContainer = styled.div`
  display: inline-block;
  width: 100%;
  span {
    display: inline-block;
    line-height: 13px;
  }
`;

export const InfoContainer = styled.div`
  display: inline-block;
  position: relative;
`;
