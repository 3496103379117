import React, { FC } from 'react';
import { Typography } from 'av8-ui';
import styled from 'styled-components';

import { CardPreview } from '../common/components';
import { textContent } from '../../../utils';

interface BillingCardProps {
  name: string;
  brand: string;
  last4: string;
  exp_month: string;
  exp_year: string;
}

const Grid = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-template-areas:
    'title0 content0'
    'title1 content1'
    'title2 content2';
  margin: 0px 0px 15px 0px;
`;

const CellContainer = styled.div``;

const Cell = ({
  index,
  isTitle,
  text,
}: {
  index: number;
  isTitle: boolean;
  text: string;
}) => {
  const gridArea = (isTitle ? 'title' : 'content') + index;
  const Font = ({ text }: { text: string }) =>
    isTitle ? (
      <Typography variant="Body" style={{ color: 'grey' }}>
        {text}
      </Typography>
    ) : (
      <Typography variant="Body">{text}</Typography>
    );
  if (gridArea === 'content1') return <CardPreview variant="thin" />;
  return (
    <CellContainer style={{ gridArea }}>
      <Font text={text}></Font>
    </CellContainer>
  );
};

export const BillingCard: FC<BillingCardProps> = ({
  name,
  brand,
  last4,
  exp_month,
  exp_year,
}) => {
  const { nameOnCard, card, expires } = textContent.billing.details;
  const { endingIn } = textContent.billing;

  const expiration = `${exp_month}/${exp_year?.slice(2)}`;
  const cardId = `${brand} ${endingIn(last4)}`;
  return (
    <>
      <Grid>
        {[nameOnCard, card, expires].map((title, index) => (
          <Cell key={index} isTitle={true} text={`${title}:`} index={index} />
        ))}
        {[name, cardId, expiration].map((content, index) => (
          <Cell key={index} isTitle={false} text={content} index={index} />
        ))}
      </Grid>
    </>
  );
};
