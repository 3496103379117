import React from 'react';
import { A8Button } from 'av8-ui';

import {
  RemovePaymentButtonContainer,
  SmallerCancelButtonContainer,
} from './containers';
import { textContent } from '../../../utils';

export const SaveButton = ({
  onClick,
  isActive,
}: {
  onClick: (e: any) => Promise<void>;
  isActive: boolean;
}) => {
  return (
    <div
      style={{ gridArea: 'right', justifySelf: 'start' }}
      data-meta-action={`save-payment-method-${
        isActive ? 'enabled' : 'disabled'
      }`}
    >
      <A8Button
        style={{ backgroundColor: !isActive ? '#bfbfe4' : undefined }}
        disabled={!isActive}
        wide={true}
        onClick={onClick}
        variant="contained"
      >
        {textContent.billing.save}
      </A8Button>
    </div>
  );
};

export const CancelButton = ({
  onClick,
  isActive,
}: {
  onClick?: (e: any) => void;
  isActive: boolean;
}) => {
  return (
    <div
      style={{ gridArea: 'left', justifySelf: 'end' }}
      data-meta-action="close-edit-payment-modal"
    >
      <A8Button
        disabled={!isActive}
        wide={true}
        style={{
          color: !isActive ? '#bfbfe4' : '#2A29A6',
          borderColor: !isActive ? '#bfbfe4' : undefined,
        }}
        onClick={onClick}
        variant="outlined"
      >
        {textContent.billing.cancel}
      </A8Button>
    </div>
  );
};

export const RemovePaymentButton = ({
  onClick,
}: {
  onClick?: (e: any) => void;
}) => {
  return (
    <RemovePaymentButtonContainer data-meta-action="confirm-remove-payment-method">
      <A8Button
        wide={true}
        style={{ color: 'white' }}
        onClick={onClick}
        variant="contained"
      >
        {textContent.billing.confirmRemove}
      </A8Button>
    </RemovePaymentButtonContainer>
  );
};

export const SmallerCancelButton = ({
  onClick,
}: {
  onClick?: (e: any) => void;
}) => {
  return (
    <SmallerCancelButtonContainer data-meta-action="close-remove-payment-modal">
      <A8Button
        wide={true}
        style={{ color: '#2A29A6' }}
        onClick={onClick}
        variant="outlined"
      >
        {textContent.billing.cancel}
      </A8Button>
    </SmallerCancelButtonContainer>
  );
};
