import styled from 'styled-components';
import { variant } from 'styled-system';

import { ButtonContainer } from '../common/containers';

interface Props {
  variant?: string;
}

export const FormInputContainer = styled.div<Props>`
  margin-bottom: 5px;
  label {
    margin-bottom: 0px;
  }
  fieldset {
    border-color: ${props => (props?.variant !== 'error' ? `2a29a6` : `red`)};
  }
`;

export const CardElementContainer = styled('div')<Props>(
  {
    border: '1px solid #2a29a6',
    borderRadius: '4px',
    maxHeight: '40px',
    marginBottom: '10px',
    marginTop: '10px',
    paddingTop: '10px',
    paddingBottom: '10px',
    minHeight: '15px',
    paddingLeft: '10px',
  },
  variant({
    variants: {
      error: {
        border: '1px solid red',
      },
    },
  }),
);

export const RemovePaymentButtonContainer = styled(ButtonContainer)`
  margin: 30px 10% 0 0;
  min-width: 180px;
`;

export const SmallerCancelButtonContainer = styled(ButtonContainer)`
  margin: 30px -7px 0 3px;
`;

export const ErrorMessageContainer = styled('div')<Props>(
  {
    paddingLeft: '15px',
  },
  variant({
    variants: {
      primary: {
        marginTop: '-15px',
      },
      secondary: {
        marginTop: '-10px',
      },
    },
  }),
);

export const LoadingContainer = styled.div`
  margin-top: -200px;
`;
