import { createTheme } from '@material-ui/core/styles';
import { BookmaniaFontFamily, StyreneAFontFamily } from './fonts';

export const defaultTheme = createTheme({
  typography: {
    fontFamily: StyreneAFontFamily,
    h1: {
      fontFamily: BookmaniaFontFamily,
    },
    h2: {
      fontFamily: BookmaniaFontFamily,
    },
    h3: {
      fontFamily: BookmaniaFontFamily,
    },
    h4: {
      fontFamily: BookmaniaFontFamily,
    },
    h5: {
      fontFamily: BookmaniaFontFamily,
    },
    h6: {
      fontFamily: BookmaniaFontFamily,
    },
  },
  palette: {
    primary: {
      main: '#2a29a6',
    },
  },
});
