import axios from 'axios';

const authServiceBaseURL =
  process.env.REACT_APP_AUTH_SERVICE_URL || 'https://staging.auth.avenue8.com';

const marketingBASEURL =
  process.env.REACT_APP_MARKETING_BACKEND_URL || 'https://visual-marketing-backend.staging.svc.avenue8.com';

const authServiceApi = axios.create({
  baseURL: authServiceBaseURL,
});

const marketingApi = axios.create({
  baseURL: marketingBASEURL,
});

const setAuthToken = (token: string): void => {
  if (token) {
    authServiceApi.defaults.headers.common.Authorization = `Bearer ${token}`;
    marketingApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

export { authServiceApi, marketingApi, setAuthToken };
