import React from 'react';
import { A8Button, Typography } from 'av8-ui';

import { textContent } from '../../../utils';

export const EditButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      data-meta-action="open-edit-payment-modal"
      style={{ gridArea: 'right', justifySelf: 'start' }}
    >
      {' '}
      <A8Button wide={true} onClick={onClick} variant="contained">
        <Typography style={{ color: 'white' }} variant="Body">
          {textContent.billing.edit}
        </Typography>
      </A8Button>
    </div>
  );
};

export const RemoveButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      data-meta-action="open-remove-payment-modal"
      style={{ gridArea: 'left', justifySelf: 'end' }}
    >
      <A8Button
        wide={true}
        style={{ color: '#2A29A6' }}
        onClick={onClick}
        variant="outlined"
      >
        {textContent.billing.remove}
      </A8Button>
    </div>
  );
};
