import { prefixRoutes } from '../../utils/routes/prefixRoutes';

export const ROUTE_PREFIX = '/account/billing';

const ROUTES = {
  billingDetails: {
    base: '/billing-details',
    removeSuccess: '/billing-details?status=removeSuccess',
    updateSuccess: '/billing-details?status=updateSuccess',
    addSuccess: '/billing-details?status=addSuccess',
    removePaymentAttempt: '/billing-details?status=removePaymentAttempt',
    paymentNotVerified: '/billing-details?status=notVerified',
  },
  editPaymentDetails: {
    base: '/billing-details?status=editPayment',
    noDefaultPaymentMethod:
      '/billing-details?status=editPayment&noDefaultPaymentMethod=true',
  },
  home: '/',
  unauthorized: '/unauthorized',
};

export const ROUTE_PATHS = prefixRoutes(ROUTE_PREFIX, ROUTES);
