import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { Typography, Checkbox } from 'av8-ui';

import { DEFAULT_PAYMENT_METHOD } from '../../../services/billing-api/queries';
import { textContent } from '../../../utils';
import { cardLogos } from '../../../assets/images/cardLogos';
import { Information } from '../../../assets/images/svg';
import {
  ToolTipMessageContainer,
  ConfirmationHeaderContainer,
  ToolTipImgContainer,
  ConfirmationContainer,
  ThinCardPreviewContainer,
  CardPreviewContainer,
  CheckBoxContainer,
  ConfirmationCheckboxContainer,
  AuthorizationTextContainer,
  InfoContainer,
} from './containers';

interface Props {
  variant?: string;
}

export const HorizontalLine = styled.div<Props>`
  width: 100%;
  height: 1px;
  background-color: #f1f3ff;
  margin: auto;
  margin-top: ${props => (props?.variant === 'alt' ? '20px' : '')};
`;

const AuthorizationText = () => (
  <AuthorizationTextContainer>
    <Typography
      style={{ color: '#2A29A6', fontSize: '11px' }}
      variant="Caption"
    >
      {textContent.billing.paymentAuthorization}
    </Typography>
  </AuthorizationTextContainer>
);

const ConfirmationCheckbox = ({ onClick }: { onClick: () => void }) => {
  return (
    <ConfirmationCheckboxContainer>
      <CheckBoxContainer>
        <Checkbox className="chekky" onClick={onClick} />
      </CheckBoxContainer>
      <AuthorizationText />
    </ConfirmationCheckboxContainer>
  );
};

const ToolTipMessage = ({ isActive }: { isActive: boolean }) => {
  return (
    <ToolTipMessageContainer
      style={{ display: !isActive ? 'none' : undefined }}
    >
      <div>
        <Typography style={{ fontSize: '9px', color: 'grey' }} variant="Body">
          {textContent.billing.paymentAuthorizationAgreement}{' '}
        </Typography>
      </div>
    </ToolTipMessageContainer>
  );
};

export const ConfirmationHeader = () => {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <>
      <ConfirmationHeaderContainer>
        <Typography
          style={{ color: '#2A29A6' }}
          fontWeight="bold"
          variant="Body"
        >
          {textContent.billing.authorization}
        </Typography>
      </ConfirmationHeaderContainer>
      <InfoContainer>
        <ToolTipImgContainer>
          <div
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            <Information />
          </div>
          <ToolTipMessage isActive={showToolTip} />
        </ToolTipImgContainer>
      </InfoContainer>
    </>
  );
};

export const Confirmation = ({ onClick }: { onClick: () => void }) => {
  return (
    <>
      <ConfirmationHeader />
      <ConfirmationContainer>
        <ConfirmationCheckbox onClick={onClick} />
      </ConfirmationContainer>
    </>
  );
};

const CardLogo = styled.img`
  display: inline-block;
  padding-right: 10px;
  width: 25px;
  height: 20px;
  object-fit: scale-down;
  image-rendering:-moz-crisp-edges;          /* Firefox        */
  image-rendering:-o-crisp-edges;            /* Opera          */
  image-rendering:-webkit-optimize-contrast; /* Safari         */
  image-rendering:optimize-contrast;         /* CSS3 Proposed  */
  -ms-interpolation-mode:nearest-neighbor;   /* IE8+           */
`;

export const CardPreview = ({ variant }: { variant?: string }) => {
  const { error, data } = useQuery(DEFAULT_PAYMENT_METHOD, {
    fetchPolicy: 'cache-only',
    nextFetchPolicy: 'network-only',
  });

  if (error) console.error(error);

  const logoImg =
    cardLogos.get(data?.defaultPaymentMethod?.card?.brand) ||
    cardLogos.get('default');

  const Container =
    variant === 'thin' ? ThinCardPreviewContainer : CardPreviewContainer;

  const last4 = data?.defaultPaymentMethod?.card?.last4 || '';
  const cardType = data?.defaultPaymentMethod?.card?.funding || '';

  return (
    <Container>
      <CardLogo src={logoImg} />
      <Typography variant="Caption">
        {variant === 'thin'
          ? last4
          : textContent.billing.cardEndingIn(
              last4,
              cardType[0]?.toUpperCase() + cardType?.slice(1),
            )}
      </Typography>
    </Container>
  );
};
