import React from 'react';
import { useQuery } from '@apollo/client';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { CONFIG } from '../../services/billing-api/queries';

export const StripeContext = ({ children }: { children: JSX.Element }) => {
  const { loading, error, data } = useQuery(CONFIG);

  let Context = () => <></>;

  if (!loading) {
    const stripePromise = loadStripe(data?.config?.stripePublishableKey);
    Context = () => <Elements stripe={stripePromise}>{children}</Elements>;
  }
  if (error) {
    // TODO handle error
    console.error(error);
  }

  return <Context />;
};
