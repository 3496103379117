import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Route from './route'
import { ROUTE_PATHS } from './routes';
import { BillingDetailsPage, Unauthorized} from '../../pages';

export const Routing = () => {
  return (
    <Router>
      <div>
        <Switch>
          <Route isProtected path={ROUTE_PATHS.billingDetails.base}>
            <BillingDetailsPage />
          </Route>
          <Route isProtected path={ROUTE_PATHS.home}>
            <BillingDetailsPage />
          </Route>
          <Route isProtected={false} path={ROUTE_PATHS.unauthorized}>
            <Unauthorized />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};
