import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useHistory } from 'react-router-dom';
import { ApolloError, useMutation, useQuery } from '@apollo/client';

import { useQueryParams } from '../../../hooks';
import { ROUTE_PATHS } from '../../router/routes';
import { basicInputSchema, validFormSchema } from './helpers';
import {
  CREATE_SUBSCRIPTION,
  ADD_PAYMENT_METHOD,
  CHECKOUT_FORM_CONFIG,
  SET_PRIMARY_PAYMENT_METHOD,
  APPLY_PROMOTION_TO_SUBSCRIPTION,
  DEFAULT_PAYMENT_METHOD,
} from '../../../services/billing-api/queries';
import { keepMaxLength, textContent } from '../../../utils';

export const useFormState = () => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [isInputValid, setIsInputValid] = useState(false);
  const [name, setName] = useState('');
  const [membershipCode, setMembershipCode] = useState('');
  const [isCardValid, setIsCardValid] = useState(false);
  const [didAgree, setDidAgree] = useState(false);
  const [isUpdatingPayment, setIsUpdatingPayment] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState('');
  const [priceId, setPriceId] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const [showErrors, setShowErrors] = useState(false);

  const [nameError, setNameError] = useState('');
  const [cardError, setCardError] = useState(
    textContent.billing.errors.pleaseEnterCardInfo,
  );

  if (name.length < 2 && !nameError) {
    setNameError(textContent.billing.errors.pleaseEnterName);
  } else if (name.length > 1 && nameError) {
    setNameError('');
  }

  elements?.getElement(CardElement)?.on('change', function (event) {
    setIsInputValid(
      basicInputSchema.isValidSync({
        name,
        membershipCode,
        didAgree,
      }),
    );
    if (event.complete) {
      setIsCardValid(true);
    } else if (event.error) {
      setCardError(event.error.message);
      setIsCardValid(false);
    } else {
      setIsCardValid(false);
      setCardError('');
    }
  });

  const noDefaultPaymentMethod =
    useQueryParams().get('noDefaultPaymentMethod') === 'true';

  const { loading, error, data } = useQuery(CHECKOUT_FORM_CONFIG, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'network-only',
  });
  if (error) console.error(error);

  useEffect(() => {
    if (data && !loading) {
      if (data?.subscriptions.length)
        setSubscriptionId(data?.subscriptions[0].id);
      setIsSubscribed(data?.isSubscribed);
      setPriceId(data?.config?.agentSubscriptionId);
    }
  }, [data, loading, priceId, subscriptionId]);

  const [setPrimaryPayment] = useMutation(SET_PRIMARY_PAYMENT_METHOD, {
    refetchQueries: [DEFAULT_PAYMENT_METHOD],
    onCompleted: () => {
      if (membershipCode) applyPromotionToSubscription();
      const path = isUpdatingPayment
        ? ROUTE_PATHS.billingDetails.updateSuccess
        : ROUTE_PATHS.billingDetails.addSuccess;
      history.push(path);
    },
    onError: (error: ApolloError) => {
      console.error(error.message);
      history.push(ROUTE_PATHS.billingDetails.paymentNotVerified);
    },
  });

  const [addPaymentMethod] = useMutation(ADD_PAYMENT_METHOD, {
    refetchQueries: [CHECKOUT_FORM_CONFIG],
    onCompleted: async ({ addPaymentMethod: { clientSecret } }) => {
      if (elements == null) {
        return;
      }
      if (stripe) {
        try {
          const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement) || { token: '' },
              billing_details: {
                name,
              },
            },
          });
          if (result.error) throw result.error.message;
          setPrimaryPayment({
            variables: {
              defaultPaymentId: result.setupIntent?.payment_method,
            },
          });
        } catch (e) {
          console.error(e);
          history.push(ROUTE_PATHS.billingDetails.paymentNotVerified);
        }
      }
    },
    onError: (error: ApolloError) => {
      console.error(error.message);
      history.push(ROUTE_PATHS.billingDetails.paymentNotVerified);
    },
  });

  const [createSubscription] = useMutation(CREATE_SUBSCRIPTION, {
    variables: {
      priceId,
      promotionCode: membershipCode ? membershipCode : undefined,
    },
    refetchQueries: [CHECKOUT_FORM_CONFIG],
    onCompleted: async ({ createSubscription: { clientSecret } }) => {
      if (elements == null) {
        return;
      }
      if (stripe) {
        try {
          let { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: elements.getElement(CardElement) || { token: '' },
              billing_details: {
                name,
              }
            }
          });

          if (error) throw error?.message;
          const defaultPaymentId = paymentIntent?.payment_method;
          setPrimaryPayment({ variables: { defaultPaymentId } });
        } catch (e) {
          console.error(e);
          history.push(ROUTE_PATHS.billingDetails.paymentNotVerified);
        }
      }
    },
    onError: (error: ApolloError) => {
      console.error(error.message);
      history.push(ROUTE_PATHS.billingDetails.paymentNotVerified);
    }
  });

  const [applyPromotionToSubscription] = useMutation(
    APPLY_PROMOTION_TO_SUBSCRIPTION,
    {
      variables: { promotionCode: membershipCode, subscriptionId },
      onError: (error: ApolloError) => {
        console.error(error.message);
        history.push(ROUTE_PATHS.billingDetails.paymentNotVerified);
      },
    },
  );

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    keepMaxLength(event);
    setName(event.target.value);
    setIsInputValid(
      basicInputSchema.isValidSync({
        isCardValid,
        name: event.target.value,
        membershipCode,
        didAgree,
      }),
    );
  };

  const handleMembershipCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    keepMaxLength(event);
    setMembershipCode(event.target.value);
  };

  const handleAgreementChange = () => {
    setDidAgree(!didAgree);
    setIsInputValid(
      validFormSchema.isValidSync({
        isCardValid,
        name,
        membershipCode,
        didAgree: !didAgree,
      }),
    );
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const isValid = validFormSchema.isValidSync({
      isCardValid,
      name,
      membershipCode: membershipCode.trim(),
      didAgree,
    });

    if(!isValid) {
      if (!showErrors) setShowErrors(true);
      if (!isCardValid && !cardError)
        setCardError(textContent.billing.errors.pleaseEnterCardInfo);
      return;
    }

    if (isSubscribed) {
      setIsUpdatingPayment(true);
      addPaymentMethod();
    } else {
      setIsUpdatingPayment(false);
      createSubscription();
    }  
  };

  return {
    history,
    isInputValid,
    noDefaultPaymentMethod,
    handleNameChange,
    handleMembershipCodeChange,
    handleAgreementChange,
    handleSubmit,
    isCardValid,
    cardError,
    nameError,
    showErrors,
    name,
    membershipCode,
    didAgree,
  };
};
