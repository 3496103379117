import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Loading } from 'av8-ui';
import { useAuthContext } from '../../context/AuthContext';
import { useRequestContext } from '../../context/RequestContext';
import { setAuthToken } from '../../services/api';
import { checkTokenValidation } from '../../services/marketingServices';

export const getIsEmbedded = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  let isEmbedded = localStorage.getItem('isEmbedded');

  if (isEmbedded) {
    return isEmbedded === 'true';
  } else {
    const isEmbeddedFromURL = urlParams.get('mbd') === 'true';
    localStorage.setItem('isEmbedded', isEmbeddedFromURL.toString());
    return isEmbeddedFromURL;
  }
};

export const getAgentId = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const agentId = urlParams.get('agentId');
  return agentId;
};

const AppInitialization = ({ children }: { children:  React.ReactNode }) => {
  const [loading, setLoading] = useState(true);
  const { setIsEmbeddedCtx, isEmbedded } = useRequestContext();
  const { setAgentId, setIsAuthed, setAuthSBL } = useAuthContext();

  const urlParams = new URLSearchParams(window.location.search);

  const setupIsEmbedded = () => {
    setIsEmbeddedCtx(getIsEmbedded());
  };

  const setupAuthFromQueryParams = async () => {
    const agentId = urlParams.get('agentId');
    const token = urlParams.get('token');
    
    if (token && agentId) {
      // set token in APIs before validate token
      setAuthToken(token);
      const response = await checkTokenValidation(agentId);
      if (response.success) {
        setIsAuthed(true);
        setAgentId(agentId);
        localStorage.setItem('token', token);
        localStorage.setItem('agentId', agentId);
      }
    }
  };

  useEffect(() => {
    let authSBL: any = null;

    const authChangeHandler = async (credentials: { token?: string, agentId?: string}) => {
      const { token = null, agentId = null } = credentials || {};
      if (isEmbedded && agentId) {
      const response = await checkTokenValidation(agentId);
        if (!response.success) {
          setIsAuthed(false);
          return;
        }
      }

      if (token && agentId) {
        setAuthToken(token);
        setAgentId(agentId);
        setIsAuthed(!!token);
        localStorage.setItem('token', token);
        localStorage.setItem('agentId', agentId);
      }
    };

    const setupAuthFromAuthSBL = async (): Promise<void> => {
      authSBL = 'getAuthSBLInstance' in (window as any).av8.auth // TODO: add proper global av8 types
          ? (window as any).av8.auth.getAuthSBLInstance()
          : null;
  
      if (!authSBL) {
        // TODO: use Sentry directly to warn about this critical issue with a fallback
        Sentry.captureException(
          `No auth sbl found at "${process.env.REACT_APP_SBL_BUNDLE_URL}"`,
        );
        throw new Error(
          `No auth sbl found at "${process.env.REACT_APP_SBL_BUNDLE_URL}"`,
        );
      }

      setAuthSBL(authSBL);

      if (authSBL.addOnAuthChangeListener && authSBL.getCredentials) {
        authSBL.addOnAuthChangeListener(authChangeHandler);
        const credentials = await authSBL.getCredentials();
        const { token = null } = credentials || {};
  
        if (token) {
          setAuthToken(token);
          await authChangeHandler(credentials);
          return;
        }
      } else {
        // TODO: use Sentry to warn about this critical issue
        console.error(
          `Incompatible authSBL detected from "${process.env.REACT_APP_SBL_BUNDLE_URL}"!`,
        );
      }

      // fallback to query params if SBL token is not valid
      await setupAuthFromQueryParams();
    };

    setupIsEmbedded();
    setupAuthFromAuthSBL().then(() => setLoading(false));
    return () => authSBL.removeOnAuthChangeListener(authChangeHandler);
  });

  if (loading) {
    return <Loading />;
  }
  return <>{children}</>;
};

export default AppInitialization;
