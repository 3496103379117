import * as yup from 'yup';

export const validFormSchema = yup.object().shape({
  isCardValid: yup.boolean().required().oneOf([true]),
  name: yup.string().required().min(2),
  membershipCode: yup.string().optional(),
  didAgree: yup.boolean().required().oneOf([true]),
});

export const basicInputSchema = yup.object().shape({
  name: yup.string().required().min(1),
  membershipCode: yup.string().optional(),
  didAgree: yup.boolean().required().oneOf([true]),
});
