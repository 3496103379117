import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import { ButtonRow } from '../components/billing/common/containers';
import {
  REMOVE_PAYMENT_METHOD,
  DEFAULT_PAYMENT_METHOD,
} from '../services/billing-api/queries';
import { BillingDetails } from '../components/billing';
import { ROUTE_PATHS } from '../components/router/routes';
import { BasePage } from '../components/common';
import { HorizontalLine } from '../components/billing/common/components';
import {
  Title,
  Description,
  Subheading,
} from '../components/billing/common/textComponents';
import { RemoveButton, EditButton } from '../components/billing/common/buttons';
import { useQueryParams } from '../hooks';
import {
  PaymentMethodModal,
  RemovePaymentModal,
  EditPaymentModal,
} from '../components/modal';

export const BillingDetailsPage = () => {
  const history = useHistory();

  const status = useQueryParams().get('status') || '';

  const { data, error } = useQuery(DEFAULT_PAYMENT_METHOD, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'network-only',
  });

  if (error) console.error(error);

  const [removePaymentMethod, { loading }] = useMutation(
    REMOVE_PAYMENT_METHOD,
    {
      variables: { paymentId: data?.defaultPaymentMethod?.id },
      refetchQueries: [DEFAULT_PAYMENT_METHOD],
      onCompleted: async () => {
        history.push(ROUTE_PATHS.billingDetails.removeSuccess);
      },
    },
  );

  return (
    <BasePage>
      <Title />
      <Subheading active={status === 'updateSuccess' || status === 'addSuccess'} />
      <Description />
      <BillingDetails />
      <HorizontalLine />
      <ButtonRow
        style={{ display: data?.defaultPaymentMethod ? undefined : 'none' }}
      >
        <RemoveButton
          onClick={() =>
            history.push(ROUTE_PATHS.billingDetails.removePaymentAttempt)
          }
        />
        <EditButton
          onClick={() => history.push(ROUTE_PATHS.editPaymentDetails.base)}
        />
      </ButtonRow>
      <PaymentMethodModal variant={status} isActive={!!status} />
      <RemovePaymentModal
        isActive={status === 'removePaymentAttempt'}
        loading={loading}
        handleCancel={() => history.push(ROUTE_PATHS.billingDetails.base)}
        handleRemove={removePaymentMethod}
      />
      <EditPaymentModal isActive={status === 'editPayment'} />
    </BasePage>
  );
};
