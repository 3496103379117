/* eslint-disable no-constant-condition */
import { marketingApi } from './api';

export const checkTokenValidation = async (agentId: string) => {
  try {
    const { data } = await marketingApi.get(`/secret?agentId=${agentId}`);
    return { success: true, data };
  } catch (err) {
    return { success: false };
  }
};
